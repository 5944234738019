.sidebar {
    width: 230px;
    padding: 15px;
    border-right: 1px solid #dfe0eb;
}

.title {
    padding-bottom: 10px;
    font-weight: 600;
    font-size: 18px;
    line-height: 120%;
    text-align: center;
    color: #252733;
    border-bottom: 1px solid #dfe0eb;
}

.nav {
    display: flex;
    flex-direction: column;
    row-gap: 7px;
    margin-top: 20px;
    padding: 0 15px;

    ul li {
        margin-left: 20px;
        padding: 3px 0;
    }
}

.navItem {
    color: #54545d;
    font-size: 17px;
    font-weight: 500;
}

.subNav {
    li {
        margin-left: 35px !important;
    }
}

.newRow {
    margin-top: 5px;
    padding-top: 10px;
    border-top: 1px solid #dfe0eb;
}
