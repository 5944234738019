.filters{
  display: flex;
  margin-top: 15px;
  column-gap: 10px;
}

.timeframeFilter{
  background-color: transparent;
  border-radius: 4px;
  padding: 6px 10px;
  margin: -6px -10px;
  position: relative;
  width: max-content;
  cursor: pointer;
  color: #0F144580;
  transition-duration: 300ms;
  display: flex;
  z-index: 1;

  &:hover{
    color: #0F1445;
  }

  &Content{
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 100%;
    min-width: 120px;
    left: -1px;
    background-color: white;
    border: 1px solid rgba(18, 36, 67, 0.2);
    max-height: 250px;
    overflow-y: scroll;

    button{
      border-bottom: 1px solid rgba(18, 36, 67, 0.2);
      padding: 10px 10px;

      &:last-child{
        border-bottom: none;
      }
      &:hover{
        background-color: rgba(18, 36, 67, 0.05);
      }
    }
  }
}