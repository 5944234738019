.wrapper {
    & > div:not(:first-child) {
        margin-top: 12px;
    }
}

.block {
    padding: 8px;
    border: 1px solid rgba(18, 36, 67, 0.2);
    border-radius: 8px;
    background-color: #f7f9ff;
    color: #122443;
}

.blockTitle {
    padding-left: 6px;
    margin-bottom: 4px;
    font-size: 16px;
    font-weight: 600;
    font-style: italic;
    color: rgba(18, 36, 67, 0.8);
}

.inner {
    padding: 10px 15px;
    border: 1px solid rgba(18, 36, 67, 0.2);
    border-radius: 8px;
    background-color: #fff;

    p {
        line-height: 1.5;

        span {
            font-weight: 500;
        }
    }
}
