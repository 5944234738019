.item {
    list-style: none;
    padding: 10px 15px;

    &:not(:last-child) {
        border-bottom: 1px solid #dfe0eb;
    }
}

.warnItem {
    background-color: #fff2f0 !important;
}

.warnText {
    color: #b73b28 !important;
}

.header {
    display: flex;
    align-items: center;
    column-gap: 10px;
}

.name,
.nameWithWrap {
    flex: 1 1 auto;
    overflow-x: hidden;

    div:first-child {
        margin-bottom: 5px;
        white-space: nowrap;
        max-width: 100%;
    }

    div:not(:first-child) {
        margin-left: 5px;
    }

    span {
        font-size: 14px;
        color: #5b5656;

        &:first-child {
            font-style: italic;
            font-weight: 500;
        }
    }
}

.nameWithWrap {
    overflow: auto;

    div:first-child {
        white-space: pre-wrap;
    }
}

.status {
    flex: 0 0 150px;
    text-align: center;

    span {
        padding: 4px 8px;
        border-radius: 5px;
    }

    &.new span {
        background-color: #fffbe6;
        border: 1px solid #ffe58f;
        color: #a16207;
    }

    &.active span {
        background-color: #f6ffed;
        border: 1px solid #b7eb8f;
        color: #365314;
    }

    &.banned span {
        background-color: #fff2f0;
        border: 1px solid #ffccc7;
        color: #7f1d1d;
    }

    &.pilot span {
        background-color: #f0f9ff;
        border: 1px solid #7dd3fc;
        color: #2950bd;
    }
}

.more {
    margin-top: 10px;
    background-color: #fff;
    padding: 10px 15px;
    border: 1px solid #dfe0eb;
    border-radius: 8px;
    max-width: 646px;
}

.flex {
    display: flex;
    justify-content: space-between;
    column-gap: 15px;
}

.userInfo {
    flex: 1 1 auto;
    & > div:not(:last-child) {
        margin-bottom: 10px;
    }

    span:first-child {
        font-weight: 500;
        font-style: italic;
    }
}

.buttons {
    flex: 0 0 130px;
    display: flex;
    flex-direction: column;
    row-gap: 8px;

    button {
        padding: 7px 10px;

        &:disabled {
            background-color: #f8fafc;
            color: #9ca3af;
            cursor: not-allowed;
        }
    }
}

.note {
    margin-top: 10px;

    span {
        font-weight: 500;
        font-style: italic;
    }
}

.noteButtons {
    margin-top: 5px;
    display: flex;
    justify-content: center;
}

.letterSpacing {
    letter-spacing: 1px;
}

.flexRow {
    display: flex;
    align-items: center;
    column-gap: 10px;
}

.editIcon {
    width: 16px;
    height: 16px;
    opacity: 0.7;
    cursor: pointer;

    img {
        width: 100%;
        height: 100%;
    }
}
