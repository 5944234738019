.wrapper {
    width: 300px;
    padding: 10px 0;
}

.buttons {
    margin-top: 20px;
    text-align: center;
}

.input {
    padding: 10px !important;
    font-size: 16px;
    color: #333 !important;
    background-color: #f7f9ff !important;
    border: 1px solid rgba(18, 36, 67, 0.2);
}

.selectedFile {
    margin: 10px 0;
    font-size: 14px;
    color: #666;
}

.input[type="file"] {
    width: 100%;
    padding: 8px;
    margin-bottom: 16px;
    border: 1px solid #ddd;
    border-radius: 4px;
    background-color: #fff;
    cursor: pointer;

    &::-webkit-file-upload-button {
        padding: 6px 12px;
        background-color: #f0f0f0;
        border: 1px solid #ddd;
        border-radius: 4px;
        cursor: pointer;
        margin-right: 8px;

        &:hover {
            background-color: #e0e0e0;
        }
    }
}
