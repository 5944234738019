.modal {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.inner {
  padding: 20px;
  background: #ffffff;
  border-radius: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;

  h3 {
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0.3199999928474426px;
    text-align: center;
    text-transform: uppercase;
    color: #122443;
    margin-top: 36px;
  }

  p {
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0.3199999928474426px;
    text-align: center;
    opacity: 0.6;
    margin-bottom: 30px;
    width: 248px;
  }
}

.close {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 10px;
  top: 10px;
  background-color: rgba(0, 0, 0, 0.03);
  width: 30px;
  height: 30px;
  border-radius: 50px;

  img {
    width: 10px;
  }
}
