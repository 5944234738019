.wrapper {
    width: 300px;
    padding: 10px 0;
}

.buttons {
    margin-top: 20px;
    text-align: center;
}

.label {
    margin-top: 10px;
}

.input {
    padding: 10px !important;
    font-size: 16px;
    color: #333 !important;
    background-color: #f7f9ff !important;
    border: 1px solid rgba(18, 36, 67, 0.2);
}
