.totalData{
  display: flex;
  gap: 10px;
  width: 100%;
  margin-top: 30px;
}

.totalDataCard{
  background-color: #E7F0FF;
  width: 50%;
  height: 100px;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  column-gap: 20px;

  &:hover{
    background-color: #2330BA;

    .totalDataNumber, .totalDataLabel{
      color: white;
    }

    .iconHolder{
      border: 1px solid white;
    }
  }
}

.iconHolder{
  background-color: #2330BA;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.totalDataTextBlock{
  display: flex;
  flex-direction: column;
}

.totalDataNumber{
  margin-top: 3px;
  font-size: 30px;
  font-weight: 500;
  color: #2330BA;
}

.totalDataLabel{
  font-size: 12px;
  font-weight: 500;
  color: #0F1445;
}