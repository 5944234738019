.byMethodStatisticData {
    background-color: white;
    border-radius: 20px;
    padding: 60px 30px 20px;
}

.backLink {
    display: flex;
    column-gap: 5px;
    align-items: center;
    margin-bottom: 10px;
}

.filters {
    display: flex;
    column-gap: 20px;
}
.filtersText {
    margin-top: 16px;
    font-size: 16px;
    font-weight: 500;
    color: rgba(15, 20, 69, 0.5);
}

.heading {
    font-size: 45px;
    font-weight: 500;
    color: #0f1445;
}

.headerRow {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.descriptionBlock {
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    margin-top: 13px;
}

.bigDescription,
.description {
    display: flex;
    column-gap: 30px;
}

.bigDescriptionField {
    font-size: 14px;
    font-weight: 500;
    line-height: 14px;
    text-align: left;
    color: #0f1445;
}

.bigDescriptionValue {
    font-size: 14px;
    font-weight: 500;
    line-height: 14px;
    text-align: left;
    color: #0f144580;
}

.descriptionField {
    font-size: 14px;
    font-weight: 500;
    line-height: 12px;
    text-align: left;
    color: #0f1445;
}

.descriptionValue {
    font-size: 14px;
    font-weight: 500;
    line-height: 12px;
    text-align: left;
    color: #0f144580;
}

.exportButton {
    max-height: 40px;
    display: flex;
    column-gap: 5px;
    padding: 8px 20px;
    align-items: center;
    justify-content: center;
    background-color: #e7f0ff;
    border-radius: 10px;
}

.exportButtonText {
    font-size: 14px;
    font-weight: 500;
    color: #0f1445;
}

.content {
    padding: 20px;
    border-radius: 10px;
    border: 1px solid #0f14451a;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    margin-top: 15px;
}

.scalesTitle {
    margin-top: 30px;
    font-size: 16px;
    font-weight: 500;
    line-height: 1;
    color: #0f1445;
}
