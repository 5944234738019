.item {
  list-style: none;
  padding: 10px 15px;

  &:not(:last-child) {
    border-bottom: 1px solid #dfe0eb;
  }
}

.header {
  display: flex;
  align-items: center;
  column-gap: 10px;
}

.login {
  flex: 1 1 auto;

  div:last-child {
    margin-top: 5px;
  }

  span {
    font-size: 14px;
    color: #5b5656;

    &:first-child {
      font-style: italic;
      font-weight: 500;
    }
  }
}

.buttons {
  display: flex;
  column-gap: 10px;
}

.letterSpacing {
  letter-spacing: 1px;
}
