.mainDataBlock{
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  margin-top: 10px;
  padding: 20px;
  border: 1px solid #0F14451A;
  border-radius: 10px;
}

.mainDataHeader{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.mainDataHeaderText{
  font-size: 16px;
  font-weight: 600;
  color: #0F1445;
}

.periodFilterBlock{
  display: flex;
  column-gap: 5px;
}

.periodFilter{
  padding: 8px 15px;
  border-radius: 20px;
  font-size: 14px;
  font-weight: 500;
  background-color: #E7F0FF;
  color: #2330BA;
}

.periodFilterActive{
  background-color: #2330BA;
  color: white;
}

.mainDataContent{
  display: flex;
  flex-wrap: wrap;
  gap: 10px
}

.mainDataCard{
  width: calc(50% - 5px);
  min-height: 186px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #E7F0FF;
  color: #0F1445;
  position: relative;

  &:hover{
    background-color: #2330BA;
    color: white;
    .mainDataCardTextInfo{
      color: rgba(255, 255, 255, 0.3);
    }
    .mainDataCardValue{
      color: white;
    }
  }
}

.mainDataCardTextBlock{
  position: absolute;
  left: 20px;
  top: 20px
}

.mainDataCardTextHeading{
  font-size: 12px;
  font-weight: 500;
  text-align: left;
}

.mainDataCardTextInfo{
  font-size: 12px;
  font-weight: 500;
  color: #0F144580;
}

.mainDataCardValue{
  font-size: 40px;
  font-weight: 500;
  line-height: 44px;
  text-align: center;
  color: #2330BA;
}