.container {
    margin: 0 auto;
    max-width: 980px;
    padding: 0 20px 20px;
}

.loader {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 70px;
    position: absolute;
    top: 0;
}
