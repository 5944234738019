.list-wrapper {
    background-color: #f7f8fc;
    border: 1px solid #dfe0eb;
    border-radius: 8px;
}

.not-found-text {
    text-align: center;
    font-size: 16px;
}

.label {
    display: block;
    margin-bottom: 5px;
    text-align: left;
    font-size: 14px;
    color: #78716c;
    padding-left: 5px;
}

.input {
    display: block;
    margin-bottom: 10px;
    width: 100%;
    padding: 5px 10px;
    border: 1px solid #dfe0eb;
    border-radius: 5px;
    background-color: #f3f4f6;
}
