.byMethodScaleData{

}

.heading{
  font-size: 12px;
  font-weight: 400;
  color: #0F1445;
}

.scales{
  width: 100%;
  border: 1px solid #0F14451A;
  border-radius: 10px;
  padding: 20px;
  margin-top: 5px;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.scale{
  width: 100%;
  height: 26px;
}

.scaleHolder{
  width: 100%;
  height: 10px;
  border-radius: 5px;
  border: 1px solid #E7E9EC;
  position: relative;
}

.scaleLine{
  height: 8px;
  border-radius: 5px;
}

.scaleSeparators{
  position: absolute;
  width: 100%;
  top: 0;
  display: flex;
  justify-content: space-evenly;
}

.separator{
  width: 0.5px;
  height: 10px;
  background-color: #E7E9EC;
}

.scaleNumbers{
  position: absolute;
  width: 100%;
  bottom: -14px;
  display: flex;
  justify-content: space-between;
}

.scaleNumber{
  width: 20px;

  &:nth-child(1){
    .scaleNumberText{
      text-align: left;
    }
  }

  &:nth-child(2){
    .scaleNumberText{
      margin-left: -12px;
    }
  }

  &:nth-child(4){
    .scaleNumberText{
      margin-right: -4px;
    }
  }

  &:nth-child(5){
    .scaleNumberText{
      margin-right: -12px;
    }
  }

  &:nth-child(6){
    .scaleNumberText{
      text-align: right;
    }
  }
}

.scaleNumberText{
  text-align: center;
  font-size: 12px;
  font-weight: 400;
  color: #0F144580;
}