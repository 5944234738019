.dashboardLayout{
  width: 100%;
  min-height: 100dvh;
  background-color: #E7F0FF;
  padding: 20px;
}

.container {
  margin: 0 auto;
  max-width: 1160px;
}

.loader {
  display: flex;
  align-items: center;
  height: 70px;
  position: absolute;
}