.table{
  border: 1px solid #0F14451A;
  border-radius: 10px;
  padding: 20px 20px 0;
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  flex: 1 1 0;
  max-height: 430px;
  height: calc(100dvh - 310px);
}

.headingRow{
  display: flex;
  justify-content: space-between;
  padding: 9px 20px;
  background-color: #E7F0FF;
  border-radius: 10px;
}

.headingText{
  font-size: 12px;
  font-weight: 400;
  color: #0F144580;
  width: 200px;
  text-align: center;
}

.tableRow{
  padding: 15px 20px 13px 15px;
  border-bottom: 1px solid #0F14451A;
  display: flex;
  justify-content: space-between;
  align-content: center;

  &:last-child{
    border-bottom: none;
  }
}

.tableRowText{
  font-size: 12px;
  font-weight: 400;
  color: #0F1445;
  max-width: 200px;
  width: 200px;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
}

.rows{
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
}