.statisticByParamBlock{
  background-color: white;
  width: 100%;
  padding: 60px 30px;
  border-radius: 20px;
  height: calc(100dvh - 40px);
  display: flex;
  flex-direction: column;
}

.backLink{
  display: flex;
  column-gap: 5px;
  align-items: center;
  margin-bottom: 10px;
}

.heading{
  font-size: 45px;
  font-weight: 500;
  color: #0F1445;
}