.byMethodPagination{
    height: 100px;
    margin-top: 10px;
    width: 100%;
    background-color: white;
    border-radius: 20px;
    padding: 30px 40px;
}

.row{
    display: flex;
    justify-content: space-between;
}

.pageButtons{
    display: flex;
    column-gap: 5px;
}

.pageButton{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    background-color: #E7F0FF;
    border-radius: 50%;

    color: #0F1445;
    font-size: 14px;
    font-weight: 500;

    &Active{
        background-color: #2330BA;
        color: white;
    }
}