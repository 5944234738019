.loader {
  opacity: 0.5;
  width: 25px;
  height: 25px;
  margin: 25px auto;
  border-radius: 50%;
  vertical-align: text-bottom;
  border: 3px solid #0072ff;
  border-right-color: transparent;
  animation: 0.7s linear infinite spinner-border;
}

@keyframes spinner-border {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
