.wrapper {
    display: flex;
    background-color: #fff;
    border: 1px solid #dfe0eb;
    border-radius: 8px;
    max-width: 960px;
    margin: 70px auto;
}

.main {
    flex: 1 1 100%;
    max-width: 708px;
    padding: 15px;
}
.mainDashAdmin {
    max-width: 100%;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 20px;
    padding: 0 10px 10px 10px;
    border-bottom: 1px solid #dfe0eb;

    h3 {
        margin: 0;
        font-weight: 600;
        font-size: 18px;
        line-height: 120%;
        color: #252733;
    }

    button {
        flex-shrink: 0;
    }
}

.content {
    margin-top: 20px;
}
