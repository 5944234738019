.methodsData{
  padding: 40px 20px 20px;
  border-radius: 10px;
  border: 1px solid #0F14451A;
  margin-top: 10px;
}

.methodsDataBlock{
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  margin-top: 10px;
  padding: 20px;
  border: 1px solid #0F14451A;
  border-radius: 10px;
}

.methodsDataHeader{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.methodsDataHeaderText{
  font-size: 16px;
  font-weight: 600;
  color: #0F1445;
}

.periodFilterBlock{
  display: flex;
  column-gap: 5px;
}

.periodFilter{
  padding: 8px 15px;
  border-radius: 20px;
  font-size: 14px;
  font-weight: 500;
  background-color: #E7F0FF;
  color: #2330BA;
}

.periodFilterActive{
  background-color: #2330BA;
  color: white;
}

.methodsDataHeading{
  font-size: 16px;
  font-weight: 600;
}

.methodsDataCounter{
  background-color: #E7F0FF;
  width: 100%;
  min-height: 98px;
  border-radius: 10px;
  margin-top: 30px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.methodsDataCounterText{
  font-size: 40px;
  font-weight: 500;
  color: #2330BA;
}

.methodsDataTableContent{
  display: flex;
  flex-direction: column;
  max-height: 400px;
  overflow-y: scroll;
  margin-right: -15px;
  padding-right: 15px;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
    transition-duration: 300ms;
  }

  &:hover{
    &::-webkit-scrollbar-thumb {
      background-color: #0F14451A;
      border-radius: 10px;
    }
  }
}

.methodsDataTable{
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.methodsDataTableHeading{
  display: flex;
  justify-content: space-between;
  padding: 9px 20px;
  background-color: #E7F0FF;
  border-radius: 10px;
}

.methodsDataTableHeadingText{
  font-size: 12px;
  font-weight: 400;
  color: #0F144580;
}

.methodsDataTableLine{
  width: 100%;
  min-height: 40px;
  border-bottom: 1px solid #0F14451A;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 47px 10px 20px;

  &:hover{
    background-color: #2330BA;
    color: white;
    transition-duration: 0ms;
    border-radius: 10px;

    .methodsDataTableLineCounter{
      color: white;
    }
  }

  &:last-child{
    border: 0;
  }
}

.methodsDataTableLineColor{
  min-width: 20px;
  height: 20px;
  border-radius: 5px;
}

.methodsDataTableLineNameBlock{
  display: flex;
  align-items: center;
  column-gap: 5px;
  max-width: 90%;
}

.methodsDataTableLineName{
  font-size: 12px;
  font-weight: 400;
}

.methodsDataTableLineCounter{
  font-size: 12px;
  font-weight: 400;
  color: #0F1445;
}