.searchBlock {
    margin-bottom: 20px;
    padding: 8px;
    border: 1px solid rgba(18, 36, 67, 0.2);
    border-radius: 8px;
    background-color: #f7f9ff;
}

.input {
    width: 100%;
    font-family: inherit;
    font-size: 16px;
    font-weight: 500;
    line-height: 130%;
    color: rgba(18, 36, 67, 0.8);

    &::placeholder {
        font-family: inherit;
        font-style: italic;
        opacity: 0.7;
    }
}

.inputWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 20px;
    padding: 10px 15px;
    border: 1px solid rgba(18, 36, 67, 0.2);
    border-radius: 8px;
    background-color: #fff;

    img {
        width: 20px;
        height: 20px;
        opacity: 0.7;
        cursor: pointer;
    }
}
