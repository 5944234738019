@font-face {
    font-family: 'Gilroy';
    src: url(../fonts/Gilroy/Gilroy-Light.ttf) format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: url(../fonts/Gilroy/Gilroy-Regular.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: url(../fonts/Gilroy/Gilroy-Medium.ttf) format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: url(../fonts/Gilroy/Gilroy-SemiBold.ttf) format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: url(../fonts/Gilroy/Gilroy-Bold.ttf) format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: url(../fonts/Gilroy/Gilroy-Black.ttf) format('truetype');
    font-weight: 800;
    font-style: normal;
}

*,
*:before,
*:after {
    margin: 0;
    padding: 0;
    border: none;
    outline: none;
    text-decoration: none;
    box-sizing: border-box;
    background: transparent;
}

body {
    margin: 0;
    padding: 0;
    font-size: 16px;
    font-family: 'Gilroy', sans-serif;
    background-color: #f7f8fc;
}

::-moz-selection {
    color: #fff;
    background: #5111b6;
    text-shadow: none;
}

p {
    white-space: pre-line;
}
::selection {
    color: #fff;
    background: #5111b6;
    text-shadow: none;
}

h3 {
    color: #122443;
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: 0.5px;
    margin-bottom: 1em;
}

button {
    cursor: pointer;
}

audio,
canvas,
iframe,
img,
svg,
video {
    vertical-align: middle;
}

fieldset {
    border: 0;
    margin: 0;
    padding: 0;
}

textarea {
    resize: vertical;
}

a {
    color: #54545d;
    transition: color 0.3s;

    &:hover,
    &.active {
        transition: color 0.3s;
        color: #9fa2b4;
    }
}

.button,
.link-button,
.button-icon {
    padding: 5px 10px;
    border: 1px solid #c3c4cc;
    border-radius: 5px;
    background-color: #f3f4f6;
    color: #374151;
    transition: background-color 0.3s;

    &:hover {
        background-color: #e2e8f0;
    }

    &:disabled {
        opacity: 0.6;
    }
}

.link-button {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 13px;

    &:hover {
        color: #374151;
        background-color: #e2e8f0;
    }
}

.button-icon {
    padding: 5px;
}

.textarea-autosize {
    padding: 10px;
    margin-top: 8px;
    width: 100%;
    font-family: inherit;
    font-size: 16px;
    font-weight: 500;
    line-height: 130%;
    color: rgba(18, 36, 67, 0.8);
    border: 1px solid rgba(18, 36, 67, 0.2);
    border-radius: 8px;
    background-color: #f7f9ff;
    resize: none;

    &::placeholder {
        font-family: inherit;
        font-style: italic;
        opacity: 0.7;
    }

    &:disabled {
        opacity: 0.6;
    }
}

.data-error {
    font-weight: 500;
    color: #ff5d5d;
    text-align: center;
}

.content-title {
    margin-bottom: 16px;

    h4 {
        font-size: 16px;
        font-weight: 600;
        color: #54545d;
        text-align: center;
    }
}
