.wrapper {
    display: flex;
    justify-content: center;
    margin-bottom: 16px;
}

.buttonsRow {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 10px;
}
