.dashboardStatisticContent{
    background-color: white;
    border-radius: 20px;
    padding: 60px 30px 20px;
}

.backLink{
    display: flex;
    column-gap: 5px;
    align-items: center;
    margin-bottom: 10px;
}

.heading{
    font-size: 45px;
    font-weight: 500;
}

.block {
    padding: 8px;
    border: 1px solid rgba(18, 36, 67, 0.2);
    border-radius: 8px;
    background-color: #f7f9ff;
    color: #122443;
}

.blockTitle {
    padding-left: 6px;
    margin-bottom: 4px;
    font-size: 16px;
    font-weight: 600;
    font-style: italic;
    color: rgba(18, 36, 67, 0.8);
}

.inner {
    padding: 10px 15px;
    border: 1px solid rgba(18, 36, 67, 0.2);
    border-radius: 8px;
    background-color: #fff;

    p {
        line-height: 1.5;

        span {
            font-weight: 500;
        }
    }
}

.showStatsByMethodBlock{
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-top: 20px;
}

.showStatsByMethod, .logoutButton, .getExcelReport{
    padding: 13px 20px;
    background-color: #2330BA;
    color: white;
    border-radius: 10px;
}
.getExcelReport{
    background-color: white;
    color: #2330BA;
    border: 1px solid #2330BA;
    position: relative;
    min-width: 180px;
    width: 100%;
}
.row{
    display: flex;
    justify-content: space-between;
}
.buttonsRow{
    display: flex;
    column-gap: 10px;
}