.form {
  width: 250px;
  padding: 10px 0;
}

.buttons {
  margin-top: 20px;
  text-align: center;
}

.showPassword {
  display: flex;
  justify-content: right;
  column-gap: 7px;

  label {
    display: block;
    text-align: right;
    font-size: 14px;
    color: #78716c;
  }
}
