.table {
    border: 1px solid #0f14451a;
    border-radius: 10px;
    padding: 20px 20px 0;
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    flex: 1 1 0;
    max-height: 430px;
    height: calc(100dvh - 310px);
}

.headingRow,
.headingGrid {
    display: flex;
    justify-content: space-between;
    padding: 9px 20px;
    background-color: #e7f0ff;
    border-radius: 10px;
}

.headingGrid {
    display: grid;
    grid-template-columns: 250px 300px 1fr;
    align-items: center;
    column-gap: 20px;
}

.headingText {
    font-size: 12px;
    font-weight: 400;
    color: #0f144580;
}

.tableRow {
    padding: 15px 20px 13px 15px;
    border-bottom: 1px solid #0f14451a;
    display: flex;
    justify-content: space-between;

    &:last-child {
        border-bottom: none;
    }
}

.tableRow,
.tableGrid {
    padding: 15px 20px 13px 15px;
    border-bottom: 1px solid #0f14451a;
    display: flex;
    justify-content: space-between;

    &:last-child {
        border-bottom: none;
    }
}

.tableGrid {
    display: grid;
    grid-template-columns: 250px 300px 1fr;
    align-items: center;
    column-gap: 20px;
}

.tableRowText {
    font-size: 12px;
    font-weight: 400;
    color: #0f1445;
}

.textRight {
    text-align: right;
}

.rows {
    overflow-y: scroll;

    &::-webkit-scrollbar {
        display: none;
    }
}
