.byMethodStatisticContent{
    background-color: white;
    border-radius: 20px;
    padding: 60px 30px 20px;
}

.backLink{
    display: flex;
    column-gap: 5px;
    align-items: center;
    margin-bottom: 10px;
}

.heading{
    font-size: 45px;
    font-weight: 500;
    color: #0F1445;
}

.filters{
    display: flex;
    column-gap: 20px;
}

.filtersText{
    margin-top: 16px;
    font-size: 16px;
    font-weight: 500;
    color: rgba(15, 20, 69, 0.5);
}

.description{
    margin-top: 20px;

    font-size: 18px;
    font-weight: 500;
    color: #0F1445;
}

.contentHeader{
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    column-gap: 20px;
}

.search{
    max-width: 545px;
    flex-grow: 1;
    padding: 11px 20px;
    border: 1px solid #0F14451A;
    border-radius: 10px;
    display: flex;
    align-items: center;
    column-gap: 5px;
}

.input{
    width: 100%;
    font-size: 14px;
    font-weight: 500;
    line-height: 18.2px;
    text-align: left;
    color: #0F1445;
    cursor: pointer;
}

.showMethod{
    padding: 13px 20px;
    background-color: #2330BA;
    color: white;
    border-radius: 10px;
    box-sizing: border-box;

    &Disabled{
        opacity: 0.5;
    }
}

.dateInputHolder{
    position: relative;
    display: flex;
    align-items: center;
    height: 40px;
    padding-left: 30px;
}

.dateInputSafari{
    position: absolute;

    &::-webkit-datetime-edit {
        opacity: 0;
        width: 130px;
        height: 40px;
    }
    &::-webkit-calendar-picker-indicator {
        width: 110px;
        height: 40px;
        opacity: 0;
    }
}

.dateInputMozilla{
    position: absolute;

    &::-webkit-calendar-picker-indicator{
        display: none;
    }
}

.dateInput{
    position: absolute;
    &::-webkit-datetime-edit {
        opacity: 0;
       display: none;
    }
    &::-webkit-calendar-picker-indicator {
        width: 110px;
        height: 40px;
        opacity: 0;
    }
}

.dateInputText{
    display: flex;
    column-gap: 5px;
    pointer-events: none;
}

.dateInputLabel{
    color: #0F14454D;
    font-size: 14px;
    font-weight: 500;
}

.dateInputValue{
    color:  #0F144580;
    font-size: 14px;
    font-weight: 500;
}

.contentData{
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 30px;
}

.contentCard{
    min-height: 120px;
    border-radius: 10px;
    border: 1px solid #0F14451A;
    width: calc((100% - 20px) / 3);
    padding: 20px;
    display: flex;
    flex-direction: column;
    cursor: pointer;

    &Active{
        border-color: #2330BA;
    }
}

.contentDot{
    width: 14px;
    height: 14px;
    background-color: #E7F0FF;
    border-radius: 50%;
    margin-left: 5px;
    margin-top: 5px;
}

.contentText{
    margin-top: auto;
    font-size: 14px;
    font-weight: 500;
    line-height: 18.2px;
    text-align: left;
    color: #0F1445;
}

.contentDotActive{
    width: 14px;
    height: 14px;
    background-color: #2330BA;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    &:after{
        content: "";
        width: 6px;
        height: 6px;
        background-color: white;
        border-radius: 50%;
    }
}