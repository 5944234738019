.byMethodTable{

}

.tableHeader{
  width: 100%;
  display: flex;
  padding: 9px 20px;
  border-radius: 10px;

  justify-content: space-between;
  background-color: #E7F0FF;
}

.tableHeaderText{
  font-size: 12px;
  font-weight: 400;
  color: #0F144580;
}

.tableHeaderNames{
  display: flex;
  column-gap: 80px;
  align-items: center;
}

.tableHeaderName{
  display: flex;
  column-gap: 5px;
  align-items: center;
  width: 74px;
}

.tableHeaderNameDot{
  width: 12px;
  height: 12px;
  border-radius: 50%;
}

.tableHeaderNameText{
  font-size: 12px;
  font-weight: 400;
  color: #0F144580;
}

.tableContentRow{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 13px 12px 15px 20px;
  border-bottom: 1px solid #0F14451A;

  &:last-child{
    border-bottom: none;
  }
}

.tableContentRowHeading{
  font-size: 12px;
  font-weight: 400;
  color: #0F144580;
}

.tableContentRowData{
  display: flex;
  column-gap: 80px;
}

.tableContentRowValue{
  width: 74px;
  text-align: center;
  font-size: 12px;
  color: #0F1445;
}