.wrapper {
    display: flex;
    justify-content: center;
    gap: 7px;
    flex-wrap: wrap;
    padding: 0 20px;
    margin-top: 15px;

    button:disabled {
        background-color: #f8fafc;
        color: #9ca3af;
    }
}

.active {
    background-color: #cdcdcd !important;
}
